import { createSlice } from '@reduxjs/toolkit'

const localDataIncomplete = localStorage.getItem('todos')
const localDataCompleted = localStorage.getItem('completedTodos')


const todos = localDataIncomplete ? JSON.parse(localDataIncomplete) : []
const completedTodos = localDataCompleted ? JSON.parse(localDataCompleted) : []



export const counterSlice = createSlice({
  name: 'todos',
  initialState: {
    todos: todos,
    completedTodos: completedTodos
  },
  reducers: {
    addTodo: (state, action) => {
      state.todos.push(action.payload)
    },
    markTodoAsComplete: (state, action) => {
      let doneTodo = state.todos.splice(action.payload, 1)
      state.completedTodos.push(doneTodo)
    },
    removeTodoFromComplete: (state, action) => {
      state.todos.push(action.payload.todo)
      state.completedTodos.splice(action.payload.index, 1)
    },
    delTodo: (state, action) => {
      state.todos.splice(action.payload, 1)
    },
    delTodoFromCompleted: (state, action) => {
      state.completedTodos.splice(action.payload, 1)
    }
  },
})

// Action creators are generated for each case reducer function
export const { addTodo, markTodoAsComplete, removeTodoFromComplete, delTodo, delTodoFromCompleted } = counterSlice.actions

export default counterSlice.reducer