import { useDispatch } from 'react-redux';
import { addTodo } from './redux/todosSlice'
import { useState } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';

import './App.css';
import Todos from './components/Todos';
import CompletedTodos from './components/CompletedTodos';

function App() {
  const dispatch = useDispatch()

  const [todo, setTodo] = useState('')

  const { pathname } =  useLocation()

  return (
    <div className="App">
      <h2>
        My Todos
        </h2>
      <form onSubmit={e => e.preventDefault()}>
      <input type='text' value={todo} onChange={(e) => {setTodo(e.target.value)}} />

      <div>
      <button 
      className='addbtn'
      onClick={() => {
        if(!todo){
          return
        }
        dispatch(addTodo(todo))
        setTodo('')
      }}>Add Todo</button>
      </div>
      </form>
      <p className='info'>Tap on/click a task to mark it as complete</p>
      <nav>
        <Link to="todos" className={pathname === "/" || pathname === "/todos" ? "active-tab nav-tabs" : "nav-tabs"}>Todos</Link>
        <Link to="completedTodos" className={pathname === "/completedTodos" ? "active-tab nav-tabs" : "nav-tabs"}>Completed</Link>
      </nav>
      <hr />
      <Routes>
        <Route path='/' element={<Todos />} />
        <Route path='todos' element={<Todos />} />
        <Route path='completedTodos' element={<CompletedTodos />} />
      </Routes>

      <footer>
      <hr />
        <span>Made with ❤️ by Kim</span>
      </footer>
    </div>
  );
}

export default App;
