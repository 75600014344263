import { useEffect } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { removeTodoFromComplete, delTodoFromCompleted } from '../redux/todosSlice'


const CompletedTodos = () => {
    const dispatch = useDispatch()

    const { completedTodos } = useSelector((state) => state.completedTodos)

    useEffect(() => {
        localStorage.setItem('completedTodos', JSON.stringify(completedTodos))
      },[completedTodos])

  return (
    <div>
        {
            completedTodos.length === 0 && <p style={{fontStyle:'italic', textAlign:'center'}}>No completed tasks</p>

        }
        {            
            completedTodos.map((todo, index) => (
                <li 
                key={index}
                style={{display: "flex", alignItems: "center", justifyContent:"space-between", boxShadow:"0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", padding:".5rem", marginTop:'.8rem'}}
            >
                <span onClick={() => dispatch(removeTodoFromComplete({
                                                                        todo,
                                                                        index
                                                                    }))}

                 style={{cursor: 'pointer'}}>{todo}</span>
                <span onClick={() => dispatch(delTodoFromCompleted(index))}><BiTrash style={{color: 'red', fontSize: '1.5rem', cursor:'pointer'}} /></span>
            </li>
            ))
        }

            <div className='todos-counter'>
                {
                    completedTodos.length > 0 ? <p>{completedTodos.length} completed {completedTodos.length === 1 ? 'task' : 'tasks'}</p> : ''
                }
            </div>
    </div>
  )
}

export default CompletedTodos