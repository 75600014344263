import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markTodoAsComplete, delTodo } from '../redux/todosSlice'
import { BiTrash } from 'react-icons/bi'

const Todos = () => {
    const dispatch = useDispatch()

    const { todos } = useSelector((state) => state.todos)

    useEffect(() => {
        localStorage.setItem('todos', JSON.stringify(todos))
      },[todos])

  return (
    <div> 
         {
            todos.length === 0 && <p style={{fontStyle:'italic', textAlign:'center'}}>No tasks</p>
         }

               
        {
            todos.map((todo, index) => (
            <li 
                key={index}
                style={{display: "flex", alignItems: "center", justifyContent:"space-between", boxShadow:"0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", padding:".5rem", marginTop:'.8rem'}}
            >
                <span onClick={() => dispatch(markTodoAsComplete(index))} style={{cursor: 'pointer'}}>{todo}</span>
                <span onClick={() => dispatch(delTodo(index))}><BiTrash style={{color: 'red', fontSize: '1.5rem', cursor:'pointer'}} /></span>
            </li>
            ))
        }

            <div className='todos-counter'>
                {
                    todos.length > 0 ? <p>{todos.length} pending {todos.length === 1 ? 'task' : 'tasks'}</p> : ''
                }
            </div>
    </div>
  )
}

export default Todos